<template>
	<div class="redeem">
		<w-navTab titleText="爱心超市"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="head-picture"><div class="head-title">您的爱心积分：{{userLove}}</div></div>
			<div class="head-nav"><W-Title title="爱心超市"></W-Title></div>

			<div class="middle">
				<div class="middle-item" v-for="item in goodsList" @click="$router.push({ name: 'GoodsDetail', params: { goodsId: item.id } })">
					<div class="item-picture"><img :src="item.picture[0]" alt="" /></div>
					<div class="item-bottom">
						<div class="item-bottom-title">{{ item.title }}</div>
						<div class="item-bottom-box">
							<div class="box-left">{{ item.integral }}积分</div>
							<div class="box-right">库存：{{ item.stock }}</div>
						</div>
					</div>
				</div>
			</div>
		</VantList>
	</div>
</template>

<script>
import GoodsApi from '@/api/goods';
import UserApi from '@/api/user';
import VantList from '@/components/VantList';
import WTitle from '@/components/Title';
import VantVendor from '@/vendor/vant';
import LoadingPage from '@/components/LoadingPage';
import keepMiXin from '@/mixins/keep';
import scrollMiXin from '@/mixins/scroll';

const keep = keepMiXin(['GoodsDetail']);
const scroll = scrollMiXin(['.list-view']);

export default {
	name: 'Redeem',
	mixins: [scroll, keep],
	inject: ['themeColor'],
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			goodsList: [],
			loadingPage: true,
			paramsPage: 1,
			paramsLimit: 10,
			userLove:""
		};
	},
	created() {
		this.onLoad();
		this.getUserInfo()
	},
	activated() {
		this.onLoad();
		this.getUserInfo()
	},
	methods: {
		getUserInfo(){
			UserApi.getUserInfo()
				.then(result=>{
					this.userLove = result.data.integral
				})
				.catch(error=>{})
		},
		onLoad() {
			this.loading = true;

			let params = {
				page: this.paramsPage,
				limit: this.paramsLimit
			};

			GoodsApi.goodsList(params)
				.then(result => {
					this.paramsPage++;
					this.goodsList = this.goodsList.concat(result.data.list);
					
					this.loading = false;
					this.loadingPage = false;

					if (this.goodsList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.goodsList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		}
	},
	components: {
		VantList,
		WTitle,
		LoadingPage
	}
};
</script>

<style lang="less" scoped>
.redeem {
	.head-picture {
		width: 100%;
		height: 200px;
		background: url(../../assets/img/test249.png);
		background-size: 100% 100%;
		position: relative;

		.head-title {
			font-weight: bold;
			font-size: 15px;
			line-height: 26px;
			text-align: center;
			color: #fff;
			position: absolute;
			bottom: 15px;
			margin-left: 50%;
			transform: translateX(-50%);
		}
	}

	.head-nav {
		padding: 15px 10px 0 10px;
		background: #f2f2f2;
		border-radius: 15px 15px 0px 0px;
		margin-top: -15px;
		position: relative;
		z-index: 2;
	}

	.middle {
		padding: 0 10px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		
		.middle-item {
			width: 172px;
			border-radius: 6px;
			overflow: hidden;
			margin-top: 10px;
			margin-bottom: 10px;
			box-sizing: border-box;
			.item-picture {
				width: 172px;
				min-width: 172px;
				height: 130px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.item-bottom {
				padding: 10px;
				background-color: #fff;
				border-radius: 0 0 6px 6px;
				.item-bottom-title {
					font-size: 16px;
					line-height: 20px;
					color: #000;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.item-bottom-box {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 5px;
					.box-left {
						font-size: 14px;
						line-height: 20px;
						color: #ff6969;
					}
					.box-right {
						font-size: 14px;
						line-height: 20px;
						color: #666;
					}
				}
			}
		}
	}
}
</style>
